import * as React from "react";
import { useState } from "react";
import { InputLabel, Typography, Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { styled } from "@mui/system";

const RegistrationForm = (props) => {
  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
        width: 500px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        
      `
  );

  const [data, setData] = useState({
    name: "",
    emailaddress: "",
    organizationname: "",
  });
  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (data.name.length < 2) {
      alert("name must be minimum 5 characters long");
    } else {
      console.log(data);
    }
  };
  // class RegistrationForm extends React.Component{
  //     constructor(props){
  //         super(props);
  //         this.state = {
  //             isDisabled:true
  //         }
  //         this.submitForm = this.submitForm.bind(this);
  //     }
  // }
  // validateEmail = (email) => {
  //     const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
  //     const result = pattern.test(email);
  //     if (result===true){
  //         this.setState({
  //             emailError:false,
  //             email:email
  //         })
  //     } else{
  //         this.setState({
  //             emailError:true
  //         })
  //     }
  // }
  // handleChange = (e) => {
  //     const target = e.target;
  //     const value = target.type === 'checkbox' ? target.checked : target.value;
  //     const name = target.name;
  //     this.setState({
  //         [name]: value
  //     });
  //     if(e.target.name ==='name'){
  //         if(e.target.value==='' ||  e.target.value===null ) {
  //             this.setState({
  //                 nameError:false,
  //                 Name:e.target.value
  //             })
  //         }
  //     }

  //     if(e.target.name==='emailaddress'){
  //         this.validateEmail(e.target.value);
  //     }
  //     if(e.target.name==='organizationname'){
  //         if(e.target.value==='' || e.target.value===null){
  //             this.setState({
  //                 organizationnameError:true
  //             })
  //         } else{
  //             this.setState({
  //                 organizationnameError:false,
  //                 organizationName:e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name==='streetaddress'){
  //         if(e.target.value==='' || e.target.value===null){
  //             this.setState({
  //                 streetaddressError:true
  //             })
  //         } else{
  //             this.setState({
  //                 streetaddressError:false,
  //                 StreetAddress:e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name==='jobtitle'){
  //         if(e.target.value==='' || e.target.value===null){
  //             this.setState({
  //                 jobtitleError:true
  //             })
  //         } else{
  //             this.setState({
  //                 jobtitleError:false,
  //                 jobTitle:e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name==='city'){
  //         if(e.target.value==='' || e.target.value===null){
  //             this.setState({
  //                 cityError: true
  //             })
  //         } else{
  //             this.setState({
  //                 cityError: false,
  //                 city: e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name==='phonenumber'){
  //         if(e.target.value==='' || e.target.value===null){
  //             this.setState({
  //                 phonenumberError:true
  //             })
  //         } else{
  //             this.setState({
  //                 phonenumberError: false,
  //                 phoneNumber: e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name==='state'){
  //         if(e.target.value ==='' || e.target.value === null){
  //             this.setState({
  //                 stateError: true
  //             })
  //         } else{
  //             this.setState({
  //                 stateError: false,
  //                 state: e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name==='faxnumber'){
  //         if(e.target.value==='' || e.target.value=== null){
  //             this.setState({
  //                 faxnumberError: true
  //             })
  //         } else{
  //             this.setState({
  //                 faxnumberError: false,
  //                 faxNumber: e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name=== 'zip'){
  //         if(e.target.value==='' || e.target.value === null){
  //             this.setState({
  //                 zipError: true
  //             })
  //         } else{
  //             this.setState({
  //                 zipError: false,
  //                 zip: e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name=== 'desiredusername'){
  //         if(e.target.value==='' || e.target.value === null){
  //             this.setState({
  //                 desiredusernameError: true
  //             })
  //         } else {
  //             this.setState({
  //                 desiredusernameError: false,
  //                 desireduserName: e.target.value
  //             })
  //         }
  //     }
  //     if(e.target.name=== 'desiredpassword'){
  //         if(e.target.value === '' || e.target.value=== null){
  //             this.setState({
  //                 desiredpasswordError: true
  //             })
  //         } else{
  //             this.setState({
  //                 desiredpasswordError: false,
  //                 desiredPassword: e.target.value
  //             })
  //         }
  //     }
  //     if(this.state.firstnameError===false && this.state.lastnameError===false && this.state.emailError===false && this.state.passwordError===false){
  //         this.setState({
  //             isDisabled:false
  //         })
  //     }
  // }
  // submitForm = (e) => {
  //     e.preventDefault();
  //     const data = {
  //         name: this.state.Name,
  //         email: this.state.email,
  //         organizationName: this.state.organizationName,
  //         streetAddress: this.state.streetAddress,
  //         jobTitle: this.state.jobTitle,
  //         phoneNumber: this.state.phoneNumber,
  //         state: this.state.state,
  //         faxNumber: this.state.faxNumber,
  //         zip: this.state.zip
  //     }

  // }

  return (
    <div>
      <Typography
        variant="h6"
        className="history-header"
        sx={{ paddingTop: 2 }}
      >
        {" "}
        Traffic Information Access/Reuse Registration{" "}
      </Typography>

      <Typography
        fontSize={13}
        sx={{
          paddingTop: 1,
          paddingLeft: 1,
          paddingRight: 1,
          paddingBottom: 1,
        }}
      >
        <div>
          Users must use this form to access traffic information from the
          Illinois Department of Transportation (IDOT) Gateway Traveler
          Information System (Gateway) for purposes such as republication or
          redistribution, inputting components of traffic information from other
          sources, or establishment of a link to this IDOT website. Gateway
          traffic information includes travel time and congestion data, camera
          images, dynamic message sign legend information, lane closure and
          construction information, and incident data. Gateway traffic
          information may be accessed via a periodic download of XML data. After
          submission of this form, registrants will be notified via Email
          whether the registration has been approved, and if so, detailed access
          instructions will be provided.
        </div>
        <div>
          <b> Note: </b> Required fields are marked with an asterisk. Your
          password must be at least eight characters and contain characters from
          at least two of the following categories: lower case, upper case,
          digits, and symbols.
        </div>
        <br></br>
        <Typography variant="subtitle2" className="history-header">
          {" "}
          IDOT Traffic Information Access/Reuse Policy{" "}
        </Typography>
        <br></br>
        <div>
          {" "}
          Please read the{" "}
          <a
            href={process.env.PUBLIC_URL + "/About/InfoReusePolicy"}
            className="hyper-links"
          >
            {" "}
            IDOT Traffic Information Access/Reuse Policy{" "}
          </a>{" "}
          and indicate your agreement here: <></>
          <FormControlLabel
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
            required
            label={<Typography sx={{ fontSize: 13 }}> I agree </Typography>}
            control={<Checkbox />}
          />
        </div>
        <br></br>
        <Typography variant="subtitle2" className="history-header">
          {" "}
          Contact Information{" "}
        </Typography>
        <Box
          component="form"
          onSubmit={submitHandler}
          sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
          autoComplete="off"
        >
          <div>
            <TextField
              required="standard-required"
              label="Name"
              type="text"
              variant="standard"
              size="small"
              onChange={changeHandler}
            />

            <TextField
              required="standard-required"
              label="Email Address"
              type="Email"
              variant="standard"
              size="small"
            />
          </div>
          <div>
            <TextField
              required="standard required"
              label="Organization Name"
              variant="standard"
              type="text"
              size="small"
            />
            <TextField
              required="standard-required"
              label="Street Address"
              type="Address"
              variant="standard"
              size="small"
            />
          </div>
          <div>
            <TextField
              required="standard-required"
              label="Job Title"
              variant="standard"
              size="small"
            />
            <TextField
              required="standard-required"
              label="City"
              variant="standard"
              size="small"
            />
          </div>
          <div>
            <TextField
              required="standard-required"
              label="Phone Number"
              variant="standard"
              size="small"
            />
            <TextField
              sx={{ width: "25ch" }}
              required="standard-required"
              label="State"
              variant="standard"
              type="text"
              size="small"
            />
          </div>
          <div>
            <TextField
              // required="standard-required"
              label="Fax Number"
              type="number"
              variant="standard"
              size="small"
            />
            <TextField
              required="standard-required"
              label="Zip"
              variant="standard"
              size="small"
            />
          </div>
          <br></br>
          <Typography variant="subtitle2" className="history-header">
            {" "}
            Login Information{" "}
          </Typography>
          <div>
            <TextField
              required="standard-required"
              label="Desired User Name"
              type="text"
              variant="standard"
              size="small"
            />
            <TextField
              required="standard-required"
              label="Desired Password"
              variant="standard"
              type="password"
              autoComplete="current-password"
              size="small"
            />
          </div>
          <br></br>
          <Typography variant="subtitle2" className="history-header">
            {" "}
            Hyperlink{" "}
          </Typography>
          <div>
            <FormControlLabel
              sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
              control={<Checkbox />}
              label={
                <Typography sx={{ fontSize: 13 }}>
                  {" "}
                  <b> Request Access via Hyperlink to Website </b>{" "}
                </Typography>
              }
            />
          </div>
          <br></br>
          <Typography variant="subtitle2" className="history-header">
            {" "}
            XML Data Feed and Camera Images{" "}
          </Typography>
          <div>
            <FormControlLabel
              sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
              control={<Checkbox />}
              label={
                <Typography sx={{ fontSize: 13 }}>
                  {" "}
                  <b> XML Data Feed and Camera Images </b>{" "}
                </Typography>
              }
            />
          </div>
          <div>
            <InputLabel className="history-header">
              {" "}
              IP Address of Machine Accessing Information
            </InputLabel>

            <TextField
              disabled
              id="filled-disabled"
              // label="IP Address of Machine Accessing Information"
              variant="filled"
              size="small"
            />
          </div>
          <br></br>
        </Box>
        {/* <div> */}
        <Typography variant="subtitle2" className="history-header">
          {" "}
          Intended Usage{" "}
        </Typography>

        <div>
          {" "}
          Describe the intended usage of the traffic information, including any
          contemplated advertising*{" "}
        </div>
        <div>
          <StyledTextarea aria-label="minimum height" minRows={3} />
          <div>
            {" "}
            Where will the information be displayed? (including websites)*{" "}
          </div>
          <StyledTextarea aria-label="minimum height" minRows={3} />
        </div>

        <Typography variant="subtitle2" className="history-header">
          {" "}
          Business Associates{" "}
        </Typography>

        <div>
          List all business associates sharing/making use of the traffic
          information*
        </div>
        <div>
          {" "}
          Note: If you are distributing or earning revenue from the traffic
          information, you and your business associates will be required to sign
          a written agreement with IDOT.
        </div>
        <div>
          <FormControlLabel
            sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
            control={<Checkbox />}
            label={
              <Typography sx={{ fontSize: 13 }}>
                {" "}
                <b>
                  {" "}
                  I intend to distribute the traffic information and have listed
                  my business associates below{" "}
                </b>{" "}
              </Typography>
            }
          />
        </div>
        <div>
          <FormControlLabel
            sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
            control={<Checkbox />}
            label={
              <Typography sx={{ fontSize: 13 }}>
                {" "}
                <b>
                  {" "}
                  I intend to earn revenue from the traffic information{" "}
                </b>{" "}
              </Typography>
            }
          />
        </div>
        {/* </div> */}
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              label=" Name"
              type="text"
              variant="standard"
              size="small"
            />
            <TextField
              label="Phone Number"
              type="number"
              variant="standard"
              size="small"
            />
            <TextField
              label=" Organization Name"
              type="text"
              variant="standard"
              size="small"
            />
            <TextField
              label="Email"
              type="Email"
              variant="standard"
              size="small"
            />
            <TextField
              label="Purpose"
              variant="standard"
              type="text"
              size="small"
            />
          </div>
        </Box>

        <br></br>
        <Button variant="outlined" size="small">
          {" "}
          Add Associate{" "}
        </Button>
        <Box textAlign="center">
          <Button variant="contained" size="small" onSubmit={submitHandler}>
            {" "}
            Submit Application{" "}
          </Button>
        </Box>
      </Typography>
    </div>
  );
};

const module = {
  routeProps: {
    path: "RegistrationForm",
    component: RegistrationForm,
  },

  name: "Registration Form",
  label: "Registration Form",
  title: "Traffic Information Access/Reuse Registration",
  showOnMenu: true,
};

export default module;
