import * as React from 'react';
import L from 'leaflet';
import { Avatar, Card, CardContent, CardHeader, Table, TableCell, TableRow } from '@mui/material';


var transitStopIcon = L.icon ({
        iconUrl: "/mapicons/TransitStations/TrainStation.png",
        iconSize: [21,21], // size of the icon
        popupAnchor: [0,0] // point from which the popup should open relative to the iconAnchor
})

const getLeafletIcon = (feature, latlng, map, pane) => {
    var title = "Transit Station -" + getDesc(feature.properties);
    return L.marker(latlng, {
        icon: transitStopIcon,
        title: title,
        alt: title,
    });
};

const getDesc = (properties) => {
    return properties.description;
};

const PopupTemplate = (props) => {
    const feature = props.feature;
    return <TransitStop feature ={feature}/>;
}

const TransitStop = (props) => {
    const feature = props.feature;
    const properties = feature.properties;
    var description = ""
    if (properties.description === null || properties.description === "") {
        description = "N/A"
    }else {
        description = properties.description
    }
    return (
        <Card sx={{ borderRadius: 3 }}>
            <CardHeader
                avatar ={
                    <Avatar
                        alt="Transit Station"
                        src= {process.env.PUBLIC_URL + 
                            "/mapicons/TransitStations/TrainStation.png"
                        }
                        sx={{ width: 21, height: 21 }}
                        aria-label=" TransitStation"
                    ></Avatar>
                }
                title={<font className="layer-title"> {feature.properties.routeType} </font>}
                sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
                className={"styledtablerow"}
            />
            <CardContent
                sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                    }}
                >
                <Table size="small">
                    <TableRow>
                        <TableCell className={"layertablecellLeft"}>
                            Name
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                            {properties.name}
                        </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                        <TableCell className={"layertablecellLeft"}>
                            Description
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                            {description}
                        </TableCell>
                    </TableRow>
                    { properties.url && (
                        <TableRow>
                            <TableCell className={"layertablecellLeft"}>
                                More details
                            </TableCell>
                            <TableCell className={"reportCell layertablecellRight"}>
                                <a href= {properties.url} target="_blank" rel='noopener noreferrer' style={{textDecoration: "none"}} className="hyper-links"> Click here </a>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </CardContent>
        </Card>
    )
}

const createClusterIcon = (cluster) => {
    return L.divIcon({
        html: `<span><span>`,
        className: "cluster-transitstop",
        iconSize: L.point(21, 21, true),
    })
}

const module = {
    name: "Transit Station",
    endpoint: "/transitStopMap.json",
    showOnMap: true,
    getLeafletIcon: getLeafletIcon,
    PopupTemplate: PopupTemplate,
    mapLayerIcon: 
        process.env.PUBLIC_URL + "/mapicons/TransitStations/TrainStation.png",
    maxZoom:9,
    clusterLayer: true,
    iconCreateFunction: createClusterIcon,
    defaultOn: false

}

export default module;