import L from "leaflet";
import "../../App.css";

const shieldIcon = (number) => {
  let svg =
    '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">' +
    '<image xlink:href="' +
    process.env.PUBLIC_URL +
    '/mapicons/Shields/Interstate_Shield_A_v21.png"  x="0" y="0" rx="1" ry="1" width="100%" height="100%" style="fill:#0c6000;fill-opacity:1;fill-rule:evenodd;stroke:#ffffff;stroke-width:0.9;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>' +
    '<text x="50%" y="15" dominant-baseline="middle" text-anchor="middle" font-size="10px" style="font-style:normal;font-weight:normal;font-family:HighwayGothic,sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;">' +
    number +
    "</text>" +
    "</svg>";
  return L.divIcon({
    html: svg,
    className: "mm-icon",
  });
};

const getIcon = (feature, zoom) => {
  return shieldIcon(feature.properties.label);
};

const getLeafletIcon = (feature, latLng, map, pane) => {
  const icon = getIcon(feature);
  const title = `I-${feature.properties.label}`;
  icon.options.iconAnchor = [14, 14];
  return L.marker(latLng, { icon: icon, title: title, alt: title, pane: pane });
};

const module = {
  name: "Shield",
  endpoint: "/roadLabels.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  updateDelay: 180,
  mapLayerIcon: process.env.PUBLIC_URL + "/mapicons/InterstateShieldImage.png",
  maxZoom: 8,
  zIndex: 639,
};

export default module;
