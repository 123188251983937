import {
  getCongestionColor,
  getCongestionWidth,
  CongestionPopupTemplate,
} from "../../util/CongestionUtil";
import Box from "@mui/material/Box";
import ColorBlindButton from "../../components/ColorBlindButton";
import * as React from "react";
import ColorBlindContext from "../../context/ColorBlindContext";

const congestionIcon = {
  congestionlevels:
    process.env.PUBLIC_URL + "/mapicons/Congestion_Icon_v20.png",
};

const filter = (feature, layer) => {
  //if this feature is in IL and has unknown congestion, do not show it
  return !(
    feature.properties.id.indexOf("IL-") !== 0 && feature.properties.cng === "U"
  );
};

const style = (feature, map, isColorBlind) => {
  return {
    color: getCongestionColor(feature.properties.cng, isColorBlind),
    weight: getCongestionWidth(feature.properties.a, map),
    opacity: feature.properties.a ? 0.5 : 0.9,
    offset: feature.properties.e ? 4 : 2,
  };
};

const LegendTemplate = (props) => {
  const showColorBlind = React.useContext(ColorBlindContext);
  const url =
    process.env.PUBLIC_URL +
    (showColorBlind
      ? "/mapicons/Congestion_Icon_v20CB.png"
      : "/mapicons/Congestion_Icon_v20.png");

  return (
    <Box sx={{ display: "flex", height: "40px" }}>
      <Box sx={{ ml: 8, display: "flex" }}>
        <Box
          sx={{
            backgroundImage: `url(${url})`,
            backgroundSize: "contain",
            width: "150px",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>
      </Box>
      <Box sx={{ mt: -1 }}>
        <ColorBlindButton {...props} />
      </Box>
    </Box>
  );
};

const module = {
  name: "Congestion",
  endpoint: "/congestionMap.json",
  showOnMap: true,
  polylineLayer: true,
  filter: filter,
  style: style,
  congestionLayer: true,
  PopupTemplate: CongestionPopupTemplate,
  updateDelay: 180,
  mapLayerIcon: process.env.PUBLIC_URL + "Arterial_Congestion_v16.png",
  LegendTemplate: LegendTemplate,
  maxZoomArt: 13,
  maxZoomHwy: 7,
  zIndexHwy: 612,
  zIndexArt: 610,
};

export default module;
