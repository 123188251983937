import zIndex from "@mui/material/styles/zIndex";
import { getLeafletIcon, getTravelTimeDesc, popupOffset } from "./Icon";
import PopupTemplate from "./TravelTimesPopup";

const getTooltip = (feature) => {
  getTravelTimeDesc(feature.properties);
};

/*
 * Module Definition
 * =====================================================================
 * name: name to be displayed on layer control
 * endpoint: json endpoint on https://travelmidwest.com which provides the needed data
 * showOnMap: if this module data should show on map
 * getLeafletIcon: a definition of the module icon in leaflet format (not React)
 * PopupTemplate: a react element template for the icon popup display
 * getTooltip: returns the text displayed on the hover tooltip
 * updateDelay: how often the json data should be reloaded, defined in seconds
 *
 * Note: these elements can be defined in this file as one big
 * blob of code, or can be divided into component files in the module and imported
 */

const module = {
  name: "Travel Times",
  endpoint: "/travelTimeMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  getTooltip: getTooltip,
  updateDelay: 180,
  iconOffset: popupOffset,
  mapLayerIcon:
    process.env.PUBLIC_URL + "/mapicons/TravelTimes/Travel_Time_A_v7.png",
  maxZoom: 9,
  zIndex: 635,
};

export default module;
