// SidebarLayout.js

import * as React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NoMatch from './NoMatch';
import SidebarSelector from "./SidebarSelector";
import { useStateWithLocalStorage } from '../util/Storage';
import CustomAlert from "./CustomAlert";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const defaultFilterEndpoint = "/reportLocations.json";

const SidebarLayout = ({ module, submodules }) => {
    const [selected, setSelected] = React.useState(null);
    const [location, setLocation] = React.useState([]);
    const [hideFilter, setHideFilter] = React.useState(false);
    const [filterEndpoint, setFilterEndpoint] = React.useState(defaultFilterEndpoint);
    const [isTravelPref, setIsTravelPref] = React.useState(false);
    const [info, setInfo] = React.useState("Please Select a State, Roadway, and Direction");
    const [selectedRoadways, setSelectedRoadways] = useStateWithLocalStorage("selected.roadways", []);
    const [myLocations, setMyLocations] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("success");
    const routerLocation = useLocation();
    const navigate = useNavigate();
    const Filter = module.filter;

    // State to track if content has loaded
    const [contentLoaded, setContentLoaded] = React.useState(false);

    // Ref for the scrollable container
    const scrollContainerRef = React.useRef(null);
    // Unique key for storing scroll position based on the current path
    const scrollPositionKey = `scrollPosition_${routerLocation.pathname}`;

    React.useEffect(() => {
        if (selectedRoadways.length > 0) {
            setIsTravelPref(true);
            setSelectedRoadways(selectedRoadways);
            setLocation(selectedRoadways);
        }
    }, [routerLocation, setIsTravelPref, selectedRoadways, setSelectedRoadways]);

    React.useEffect(() => {
        const selectedModule = submodules.find(
            (smod) => routerLocation.pathname.indexOf(smod.routeProps.path) > -1
        );
        if (selectedModule) {
            setSelected(selectedModule);
            setHideFilter(selectedModule.hideFilter);
            if (selectedModule.filterEndpoint) {
                setFilterEndpoint(selectedModule.filterEndpoint);
            } else {
                setFilterEndpoint(defaultFilterEndpoint);
            }
            if (routerLocation?.state?.location) {
                setIsTravelPref(false);
                const routerCopy = { ...routerLocation.state };
                delete routerLocation.state.location;
                setLocation([routerCopy.location]);
                navigate(routerLocation.pathname, { replace: true });
            }
            if (selectedModule.name === "QuickTraffic") {
                setLocation([]);
            }
        }
    }, [routerLocation, submodules, navigate]);

    // Save scroll position on scroll
    React.useEffect(() => {
        const scrollElement = scrollContainerRef.current;
        if (scrollElement) {
            const handleScroll = () => {
                const scrollPosition = scrollElement.scrollTop;
                localStorage.setItem(scrollPositionKey, scrollPosition);
            };
            scrollElement.addEventListener('scroll', handleScroll);

            // Cleanup on unmount
            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollPositionKey]);

    // Restore scroll position after content has loaded
    React.useEffect(() => {
        if (contentLoaded) {
            const scrollElement = scrollContainerRef.current;
            if (scrollElement) {
                const savedScrollPosition = localStorage.getItem(scrollPositionKey);
                if (savedScrollPosition !== null) {
                    scrollElement.scrollTop = parseFloat(savedScrollPosition);
                }
            }
        }
    }, [contentLoaded, scrollPositionKey]);

    return (
        <Box sx={{ display: "flex", position: "relative", height: "100%" }}>
            <CustomAlert message={message} severity={severity} setMessage={setMessage} />
            <SidebarSelector submodules={submodules} />
            <Box
                sx={{
                    flexGrow: 3,
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "calc(100vh - 80px)",
                }}
            >
                {!hideFilter && Filter && (
                    <Header
                        selected={selected}
                        filterEndpoint={filterEndpoint}
                        setLocation={setLocation}
                        module={module}
                        setInfo={setInfo}
                        info={info}
                        location={location}
                        isTravelPref={isTravelPref}
                        setIsTravelPref={setIsTravelPref}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        myLocations={myLocations}
                        setMyLocations={setMyLocations}
                    />
                )}
                <Box
                    sx={{ flexGrow: 20, overflowY: "auto", mt: 0 }}
                    ref={scrollContainerRef}
                >
                    <Routes>
                        {submodules.map((sub) => (
                            <Route
                                key={sub.name}
                                path={sub.routeProps.path}
                                element={
                                    <sub.routeProps.component
                                        setSelected={setSelected}
                                        myLocations={myLocations}
                                        module={sub}
                                        setInfo={setInfo}
                                        info={info}
                                        selectedRoadways={selectedRoadways}
                                        setSelectedRoadways={setSelectedRoadways}
                                        location={location}
                                        parentModule={module}
                                        isTravelPref={isTravelPref}
                                        setIsTravelPref={setIsTravelPref}
                                        setMessage={setMessage}
                                        setSeverity={setSeverity}
                                        setContentLoaded={setContentLoaded}
                                    />
                                }
                            />
                        ))}
                        {selected && <Route path="*" element={<NoMatch />} />}
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

const Header = (props) => {
    const [locationTitle, setLocationTitle] = React.useState("");
    const [open, setOpen] = React.useState(true);
    const module = props.module;
    const Filter = module.filter;
    const selected = props.selected;
    const filterEndpoint = props.filterEndpoint;
    const setLocation = props.setLocation;
    const location = props.location;
    const isTravelPref = props.isTravelPref;
    const setIsTravelPref = props.setIsTravelPref;
    const setInfo = props.setInfo;
    const info = props.info;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;
    const myLocations = props.myLocations;
    const setMyLocations = props.setMyLocations;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        if (location.length > 0) {
            setOpen(false);
        }
    }, [location]);

    const handleChange = () => {
        setOpen((open) => !open);
    };

    if (matches) {
        return (
            <Box sx={{ p: 2, flexGrow: 1, width: "100%", flex: "1 0" }}>
                <Typography
                    variant="h6"
                    sx={{ mb: 1, textAlign: "center", mt: 2, width: "100%" }}
                >
                    {selected ? selected.title : module.error}{" "}
                    {locationTitle !== "" ? " for " : ""} {locationTitle}
                </Typography>
                {selected && Filter && (
                    <Filter
                        setLocation={setLocation}
                        report={selected}
                        location={location}
                        setLocationTitle={setLocationTitle}
                        endpoint={filterEndpoint}
                        setIsTravelPref={setIsTravelPref}
                        isTravelPref={isTravelPref}
                        setInfo={setInfo}
                        info={info}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        myLocations={myLocations}
                        setMyLocations={setMyLocations}
                    />
                )}
            </Box>
        );
    } else {
        return (
            <Accordion
                defaultExpanded={true}
                sx={{ mt: 0, mb: 1 }}
                expanded={open}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                        variant="subtitle1"
                        sx={{ mb: 1, textAlign: "center", mt: 2, width: "100%" }}
                    >
                        {selected ? selected.title : module.error}
                        <br />
                        {locationTitle}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ flexGrow: 1, width: "100%", flex: "1 0" }}>
                        {selected && Filter && (
                            <Filter
                                setLocation={setLocation}
                                report={selected}
                                location={location}
                                setLocationTitle={setLocationTitle}
                                endpoint={filterEndpoint}
                                setIsTravelPref={setIsTravelPref}
                                isTravelPref={isTravelPref}
                                setInfo={setInfo}
                                info={info}
                                setMessage={setMessage}
                                setSeverity={setSeverity}
                                myLocations={myLocations}
                                setMyLocations={setMyLocations}
                            />
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    }
};

export default SidebarLayout;
