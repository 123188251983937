import React from 'react';
import Typography from '@mui/material/Typography'
import {
    Card,
    CardHeader
} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'react-router-dom';


const getReportData = async (endpoint, setAllData, location, setError) => {
    try {
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint + "?" + new URLSearchParams({
            path: location,
        }))
        res.json().then(res => {
            setAllData(allData => {
                return { ...allData, [location]: res.reportTables };
            })
        }).catch(err => {
            setError("Unable to Retrieve Data")
            setAllData(allData => {
                return { ...allData, [location]: [] }
            });
        })
    } catch (ex) {
        setError("Unable to Retrieve Data")
        setAllData(allData => {
            return { ...allData, [location]: [] }
        });
    }
}

const hasData = (allData) => {
    const keys = Object.keys(allData);
    if (keys.length === 0) return false;
    else {
        for (let i = 0; i < keys.length; i++) {
            var key = keys[i];
            if (allData[key].length > 0) {
                return true;
            }
        }
    }
    return false;
}
const needsHashLinks = (allData) => {
    if(null === allData) return false;
    let keySet = Object.keys(allData);
    if (keySet.length === 0) return false;
    if (keySet.length > 1) return true;
    if (allData[keySet[0]].length > 1) return true;
    return false;
}

const HashLinksCamera = (props) => {
    const allData = props.allData;
    return (
        <>
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <Box sx={{ width: "99%", m: "auto", mb: 3, pr: 1, pl: 1 }}>
                    {Object.keys(allData).map((key, idxkey) => {
                        let data = allData[key][0];
                        if (data) {
                            let roadway = Object.keys(allData).length > 1 ? data.displayName : "";
                            var link = "#" + roadway;
                            return (
                                <Box sx={{ display: "inline-block" }} key={idxkey}>
                                    &nbsp;<a href={link} className="reportCell hash-link">{roadway}</a>&nbsp;
                                </Box>
                            )
                        }
                        return null;
                    })}
                </Box>
            </Box>
            <Divider></Divider>
        </>
    )
}

const CameraReport = (props) => {
    const [error, setError] = React.useState("");
    const [allData, setAllData] = React.useState(null);
    const module = props.module;
    const location = props.location;
    const DescriptionBlock = module.descriptionBlock;
    const endpoint = module.endpoint;
    const selectedRoadways = props.selectedRoadways;
    const setSelectedRoadways = props.setSelectedRoadways;
    const report = props.report;
    const setIsTravelPref = props.setIsTravelPref;
    const [useHashLinks, setUseHashLinks] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [searchParams] = useSearchParams();
    const [ isShowOld, setIsShowOld] = React.useState(false);
        React.useEffect(() => {
            const showOld = searchParams.get('showOld');
            if(null !==showOld) {
                setIsShowOld(true)
            } else{
                setIsShowOld(false);
            }
        }, [useSearchParams])

    const ageInMinutes = (ageValueString) => {
        let ageValueSplit =ageValueString.split(',');
        if(ageValueSplit.length===4){
                return parseInt((ageValueSplit[0].split(" "))[0]*24*60) + parseInt((ageValueSplit[1].split(" "))[0]*60) + parseInt((ageValueSplit[2].split(" "))[0]) + parseFloat((ageValueSplit[3].split(" "))[0]/60)
        }
        else if(ageValueSplit.length===3){
                return  parseInt((ageValueSplit[0].split(" "))[0]*60) + parseInt((ageValueSplit[1].split(" "))[0]) + parseFloat((ageValueSplit[2].split(" "))[0]/60)
        }
        else if(ageValueSplit.length===2){
                return  parseInt((ageValueSplit[0].split(" "))[0]) + parseFloat((ageValueSplit[1].split(" "))[0]/60)
        }

    }

    const oldImagePresenceCheckRoadwayWise = (data) => {
            const oldImagePresenceCheckList= data.map((d)=>
                 oldImagePresenceCheck(d)
        )

        return oldImagePresenceCheckList.includes(true)
            
    }

    const oldImagePresenceCheck = (d) => {
        var check=false
        if (d.imageDirections !== null && d.singleView !== true) {
            // var tempUrl;
            // d.imageDirections.map(eachDir)=>
            if (d.imageDirections.N != null) {
                // tempUrl = d.imageDirections.N.url;
                // directionList.push("North");
                // ageList.push(d.imageDirections.N.age)
                // imageUrls.push(ImageSource(tempUrl));
                if(ageInMinutes(d.imageDirections.N.age)>15)
                    check=true
            }
            if (d.imageDirections.S != null) {
                // tempUrl = d.imageDirections.S.url;
                // directionList.push("South");
                // ageList.push(d.imageDirections.S.age)
                // imageUrls.push(ImageSource(tempUrl));
                if(ageInMinutes(d.imageDirections.S.age)>15)
                    check=true
            }
            if (d.imageDirections.E != null) {
                // tempUrl = d.imageDirections.E.url;
                // directionList.push("East");
                // ageList.push(d.imageDirections.E.age)
                // imageUrls.push(ImageSource(tempUrl));
                if(ageInMinutes(d.imageDirections.E.age)>15)
                    check=true
            }
            if (d.imageDirections.W != null) {
                // tempUrl = d.imageDirections.W.url;
                // directionList.push("West");
                // ageList.push(d.imageDirections.W.age)
                // imageUrls.push(ImageSource(tempUrl));
                if(ageInMinutes(d.imageDirections.W.age)>15)
                    check=true
            }
    
        }
        else {
            if(ageInMinutes(d.imageAge)>15)
                check=true
            // imageUrls.push(ImageSource(d.url));
            // ageList.push(d.imageAge)
            // if (d.direction === "N")
            //     directionList.push("North");
            // if (d.direction === "S")
            //     directionList.push("South");
            // if (d.direction === "E")
            //     directionList.push("East");
            // if (d.direction === "W")
            //     directionList.push("West");
        }
        return check
    }

    React.useEffect(() => {
        setUseHashLinks(needsHashLinks(allData))
    }, [allData, setUseHashLinks])

    React.useEffect(() => {
        if (selectedRoadways.length > 0) {
            setIsTravelPref(true);
            setSelectedRoadways(selectedRoadways);
        }
    }, [report, setIsTravelPref, selectedRoadways, setSelectedRoadways])

    React.useEffect(() => {
        const reportLocations = location.filter(l => l !== undefined);
        if (reportLocations && reportLocations.length > 0) {
            setAllData(null);
            reportLocations.forEach(l => {
                if (l) getReportData(endpoint, setAllData, l, setError)
            })
        } else if (reportLocations.length === 0) {
            setAllData({});
        }
    }, [location, endpoint])

    if (!location || location.length === 0) {
        return (
            <Typography sx={{ textAlign: "center", m: 1 }} variant="h5">Please select a State and Roadway</Typography>
        )
    }
    if(null === allData) {
        return (
            <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                <CircularProgress />
            </Box>
        )
    }
    else if (Object.keys(allData).length > 0) {
        return (
            <Box sx={{ maxHeight: "100%", overflow: "auto" }}>
                <div id="top" />
                {DescriptionBlock && hasData(allData) && <Box sx={{ mb: 2 }}><DescriptionBlock module={module} /></Box>}

                {useHashLinks && <HashLinksCamera allData={allData} />}
                {Object.keys(allData).map(key => {
                    let data = allData[key][0];

                    if (Object.keys(allData).length === 1 && !data)
                        return (<Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{"There is no data to report"}</Typography>)
                    else if (data) {
                        let roadway = Object.keys(allData).length > 1 ? data.displayName : "";
                        if(!isShowOld){
                        return (
                            <Box sx={{ pr: 1, pl: 1, mb: 2 }} key={key}>
                                <Box sx={{ position: "relative", height: "100%", }}>
                                
                                    <Box sx={{ mb: 1, overflow: "auto" }}>
                                        <Box sx={{ textAlign: "center", mb: 1, width: "100%", display: "block" }} className="report-header">
                                            <Typography variant="h6" component="span" id={roadway}>{roadway}</Typography>
                                            {useHashLinks && <a href="#top" className="reportCell"><ArrowUpwardIcon fontSize="small" /></a>}
                                        </Box>
                                        {data.cells.map((d, index) => {
                                            // if(!isShowOld){
                                        return (
                                            <Card key={index}
                                                sx={{ m: 1, p: 1, width: isMobile ? "95%" : "350px", display: isMobile ? "block" : "inline-block", height: 325, verticalAlign: "top", border: "1px solid rgb(192,192,192,0.3)" }}>
                                                <CardHeader
                                                    sx={{ mb: 1, p:0 }}
                                                    title={
                                                        <Typography
                                                            component="div"
                                                            variant="body1"
                                                        > <module.cardHeader d={d} {...props} />
                                                        </Typography>}
                                                />
                                                <module.cardMedia d={d} {...props} />

                                            </Card>
                                        )
                                    // }
                                        // else if(isShowOld && oldImagePresenceCheck(d)){
                                        //     return (
                                        //         <Card key={index}
                                        //             sx={{ m: 1, p: 1, width: isMobile ? "95%" : "350px", display: isMobile ? "block" : "inline-block", height: 325, verticalAlign: "top", border: "1px solid rgb(192,192,192,0.3)" }}>
                                        //             <CardHeader
                                        //                 sx={{ mb: 1, p:0 }}
                                        //                 title={
                                        //                     <Typography
                                        //                         component="div"
                                        //                         variant="body1"
                                        //                     > <module.cardHeader d={d} {...props} />
                                        //                     </Typography>}
                                        //             />
                                        //             <module.cardMedia d={d} {...props} />
    
                                        //         </Card>
                                        //     )}
                                    }
                                    )}
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                    else if(isShowOld && oldImagePresenceCheckRoadwayWise(data.cells)){
                        return <Box sx={{ pr: 1, pl: 1, mb: 2 }} key={key}>
                                <Box sx={{ position: "relative", height: "100%", }}>
                                
                                    <Box sx={{ mb: 1, overflow: "auto" }}>
                                        <Box sx={{ textAlign: "center", mb: 1, width: "100%", display: "block" }} className="report-header">
                                            <Typography variant="h6" component="span" id={roadway}>{roadway}</Typography>
                                            {useHashLinks && <a href="#top" className="reportCell"><ArrowUpwardIcon fontSize="small" /></a>}
                                        </Box>
                                        {data.cells.map((d, index) => {
                                            if(!isShowOld){
                                        return (
                                            <Card key={index}
                                                sx={{ m: 1, p: 1, width: isMobile ? "95%" : "350px", display: isMobile ? "block" : "inline-block", height: 325, verticalAlign: "top", border: "1px solid rgb(192,192,192,0.3)" }}>
                                                <CardHeader
                                                    sx={{ mb: 1, p:0 }}
                                                    title={
                                                        <Typography
                                                            component="div"
                                                            variant="body1"
                                                        > <module.cardHeader d={d} {...props} />
                                                        </Typography>}
                                                />
                                                <module.cardMedia d={d} {...props} />

                                            </Card>
                                        )
                                    }
                                        else if(isShowOld && oldImagePresenceCheck(d)){
                                            return (
                                                <Card key={index}
                                                    sx={{ m: 1, p: 1, width: isMobile ? "95%" : "350px", display: isMobile ? "block" : "inline-block", height: 325, verticalAlign: "top", border: "1px solid rgb(192,192,192,0.3)" }}>
                                                    <CardHeader
                                                        sx={{ mb: 1, p:0 }}
                                                        title={
                                                            <Typography
                                                                component="div"
                                                                variant="body1"
                                                            > <module.cardHeader d={d} {...props} />
                                                            </Typography>}
                                                    />
                                                    <module.cardMedia d={d} {...props} />
    
                                                </Card>
                                            )}
                                    }
                                    )}
                                    </Box>
                                </Box>
                            </Box>
                    }
                    } else {
                        return null;
                    }
                })}
            </Box>
        )
    } else if(Object.keys(allData).length === 0 ) {
        return <Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{error ? error : "No Data Found"}</Typography>
    } 
}

export default CameraReport;