import L from "leaflet";
import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

var constructionIcon = L.icon({
  iconUrl:
    process.env.PUBLIC_URL + "/mapicons/Construction/Construction_A_v9.png",

  iconSize: [21, 21], // size of the icon
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const getLeafletIcon = (feature, latlng, map, pane) => {
  let title = " Construction - " + getClosureDetails(feature.properties);
  return L.marker(latlng, {
    icon: constructionIcon,
    title: title,
    alt: title,
    pane: pane,
  });
};

const getClosureDetails = (properties) => {
  return properties.closure;
};

const PopupTemplate = (props) => {
  const feature = props.feature;

  return <Construction feature={feature} />;
};

const Construction = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  const isAdmin = sessionStorage.getItem("isAdmin");

  if (isAdmin === "true") {
    return (
      <Card sx={{ borderRadius: 3 }}>
        <CardHeader
          avatar={
            <Avatar
              alt="Construction"
              src={
                process.env.PUBLIC_URL +
                "/mapicons/Construction/Construction_A_v9.png"
              }
              sx={{ width: 21, height: 21 }}
              aria-label="construction"
            ></Avatar>
          }
          title={<font className="layer-title">Road Work </font>}
          className={"styledtablerow"}
          sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
        />
        <CardContent
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Table size="small">
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                <div>{properties.locDesc}</div>
                {properties.desc}
              </TableCell>
            </TableRow>
          </Table>
          <Table size="small">
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                Closure&nbsp;Details
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.closure}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Time Periods
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.time}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>Duration</TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.dur}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>Severity</TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.sev}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>Source</TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.src}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Last Updated
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.lstUpd}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>ID</TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.id}
              </TableCell>
            </TableRow>
            {properties.mo && (
              <TableRow>
                <TableCell className={"layertablecellLeft"}>
                  Moving&nbsp;Operation
                </TableCell>
                <TableCell className={"layertablecellRight"}>
                  {String(properties.mo)}
                </TableCell>
              </TableRow>
            )}
          </Table>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card sx={{ borderRadius: 3 }}>
        <CardHeader
          avatar={
            <Avatar
              alt="Construction"
              src={
                process.env.PUBLIC_URL +
                "/mapicons/Construction/Construction_A_v9.png"
              }
              sx={{ width: 21, height: 21 }}
              aria-label="construction"
            ></Avatar>
          }
          title={<font className="layer-title">Road Work </font>}
          className={"styledtablerow"}
          sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
        />
        <CardContent
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Table size="small">
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                <div>{properties.locDesc}</div>
                {properties.desc}
              </TableCell>
            </TableRow>
          </Table>
          <Table size="small">
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                Closure&nbsp;Details
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.closure}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Time Periods
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.time}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>Duration</TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.dur}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>Severity</TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.sev}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>Source</TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.src}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Last Updated
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.lstUpd}
              </TableCell>
            </TableRow>
            {properties.mo && (
              <TableRow className={"styledtablerow"}>
                <TableCell className={"layertablecellLeft"}>
                  Moving&nbsp;Operation
                </TableCell>
                <TableCell className={"layertablecellRight"}>
                  {String(properties.mo)}
                </TableCell>
              </TableRow>
            )}
          </Table>
        </CardContent>
      </Card>
    );
  }
};

const createClusterIcon = (cluster) => {
  return L.divIcon({
    html: `<span></span>`,
    className: "cluster-construction",
    iconSize: L.point(21, 21, true),
  });
};

const module = {
  name: "Construction",
  endpoint: "/constructionMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  //getTooltip: getTooltip,
  updateDelay: 180,
  mapLayerIcon:
    process.env.PUBLIC_URL + "/mapicons/Construction/Construction_A_v9.png",
  clusterLayer: true,
  iconCreateFunction: createClusterIcon,
  maxZoom: 10,
  zIndex: 630,
};

export default module;
