import L from "leaflet";
import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getLatLng } from "../../util/MapUtil";
import zIndex from "@mui/material/styles/zIndex";

const milemarkIcon = (number) => {
  number = number.toFixed(0);
  let svg =
    '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">' +
    '<rect x="0" y="0" rx="1" ry="1" width="100%" height="100%" style="fill:#0c6000;fill-opacity:1;fill-rule:evenodd;stroke:#ffffff;stroke-width:0.9;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />' +
    '<text x="50%" y="8" dominant-baseline="middle" text-anchor="middle" font-size="12px" style="font-style:normal;font-weight:normal;font-family:HighwayGothic,sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;">MILE</text>' +
    '<text x="50%" y="22" dominant-baseline="middle" text-anchor="middle" font-size="12px" style="font-style:normal;font-weight:normal;font-family:HighwayGothic,sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;">' +
    number +
    "</text>" +
    "</svg>";
  return L.divIcon({
    html: svg,
    className: "mm-icon",
  });
};

const popupOffset = (feature, map) => {
  var screenPoint = map.latLngToLayerPoint(
    getLatLng(feature.geometry.coordinates)
  );
  var offset;
  offset = {
    x: screenPoint.x + 13,
    y: screenPoint.y + 40,
  };
  return map.layerPointToLatLng(offset);
};

const getIcon = (feature, zoom) => {
  return milemarkIcon(feature.properties.mileMarker);
};

const getLeafletIcon = (feature, latlng, map, pane) => {
  const icon = getIcon(feature);
  const title =
    feature.properties.expressway + " at " + feature.properties.mileMarker;
  icon.options.iconAnchor = [0, -20];
  return L.marker(latlng, { icon: icon, title: title, alt: title, pane: pane });
};

const PopupTemplate = (props) => {
  const feature = props.feature;
  return <MileMarker feature={feature} />;
};

const MileMarker = (props) => {
  const feature = props.feature;
  const properties = feature.properties;

  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        title={
          <font className="layer-title">
            {" "}
            Mile Marker {properties.mileMarker}{" "}
          </font>
        }
        className="styledtablerow"
        sx={{ pb: 0, paddingTop: 1, paddingBottom: 0, paddingLeft: 1 }}
      />
      <CardContent
        size="small"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <Table size="small" cellSpacing={0}>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>Expressway</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.expressway}
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>
              Mile marker
            </TableCell>
            <TableCell>
              {properties.mileMarker}
            </TableCell>
          </TableRow> */}
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>
              Nearest Cross Street
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.crossStreet}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>Offset</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.offset}
            </TableCell>
          </TableRow>
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>City</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.municipality}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>County</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.county}
            </TableCell>
          </TableRow>
        </Table>
      </CardContent>
    </Card>
  );
};

const module = {
  name: "Mile Marker",
  endpoint: "/milepostsMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  updateDelay: 180,
  mapLayerIcon: process.env.PUBLIC_URL + "/mapicons/mm.svg",
  maxZoom: 13,
  iconOffset: popupOffset,
  zIndex: 604,
};

export default module;
